export type BaseResponse<Data> = {
  success: boolean;
  data: Data;
  message: string;
  requestId: string;
};

export interface UserInfoInterface {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  name: string;
}
export interface SpotCounselingLiveCalendarResponseDto {
  liveSlotAvailable: boolean;
  liveSlot: SpotCounsellingCardDto;
  spotCounselingCalendarResponses: Array<SpotCounselingCalendarResponseDto>;
}

export interface DemoDetails {
  startTime: any;
  joinUrl: string;
  profile: PublicCounsellorInfo;
  eventCategory: EventCategory;
  vendorEventId: string;
  cancelLink: string;
  rescheduleLink: string;
  autoBooking: boolean;
  autBookingConfirmed: boolean;
  title: string;
  batchId: number;
  endTime: any;
  slug: string;
  meetingType: MeetingType;
  startDateTime: string;
}

export interface SpotCounselingCalendarResponseDto {
  date: string;
  slots: Array<SpotCounsellingCardDto>;
}
export interface SpotCounsellingCardDto {
  date: Date;
  starHour: number;
  endHour: number;
  label?: string;
  numberOfSeats: number;
  seatsBooked: number;
  batchId: number;
  slug: string;
  startMinute: number;
  endMinute: number;
  isStarted: boolean;
  meetingLink: string;
  ieltsStatus: Array<string>;
  country: Array<string>;
  minute?: number;
  duration?: number;
  originalLabel?: string;
  book_date?: string;
  slotType?: number;
}

export type UserInitialValuesDto = {
  fullName: string;
  email: string;
  preferredCountry: string;
  preferredIntake: string;
  ieltsStatus: IeltsStatus;
  preferredProgram: string;
  ieltsTargetExam: IeltsExamType;
  formId: string;
  emailId: string;
  highestLevelEducation: string;
  grades: number;
  gradeMetric: number;
  utm_campaign: string;
  utm_term: string;
  utm_source: string;
  utm_medium: string;
  form_id: string;
  campaign_type: string;
  ad_id: string;
  ieltsTime: string;
  currentEnglishLevel: string;
  prefferedTimeSlot: string;
  ieltsExamType: IeltsExamType;
  careerStage: string;
  studyAbroadCounsellingRequired: boolean;
  gclid: string;
  adName: string;
  passportStatus: string;
};
export enum PassportStatus {
  BEARER = "Bearer",
  APPLIED = "Applied",
  PENDING = "Pending",
}
export enum IeltsExamType {
  ACADEMIC = "IELTS Academic",
  GENERAL = "IELTS General",
  OTHER = "Other",
}
export enum IeltsStatus {
  NOT_DECIDED = "Not decided as of now",
  ALREADY_GIVEN = "Already given IELTS exam",
  EXAM_BOOKED = "Booked date for IELTS in next 2 months",
  PLANING_TO_BOOK = "Planning to give IELTS in next 2 months",
}

export enum PreferredCountry {
  Canada = "Canada",
  USA = "USA",
  UK = "UK",
  Germany = "Germany",
  Australia = "Australia",
  NewZealand = "NewZealand",
  Singapore = "Singapore",
  Netherlands = "Netherlands",
  China = "China",
  Ireland = "Ireland",
  RestOfEurope = "RestOfEurope",
  MiddleEasternCountries = "MiddleEasternCountries",
  OpenToAnyCountry = "OpenToAnyCountry",
  Other = "Other",
}
export interface PublicCounsellorInfo {
  name: string;
  studentsCounselled: number;
  yearsOfExperience: number;
  shortDescription: string;
  mobileImage: string;
  webImage: string;
  linkedIn: string;
  countriesOfExpertise: Array<PreferredCountry>;
  videoLink: string;
  visible: boolean;
}
export interface DemoSlotDetails {
  startTime: any;
  joinUrl: string;
  profile: PublicCounsellorInfo;
  eventCategory: EventCategory;
  vendorEventId: string;
  cancelLink: string;
  rescheduleLink: string;
  autoBooking: boolean;
  autBookingConfirmed: boolean;
  title: string;
  batchId: number;
  endTime: any;
  slug: string;
  meetingType: MeetingType;
  metaData: {
    liveBeforeMinutes: number;
  };
}
export enum EventCategory {
  REGULAR_COUNSELLING = "REGULAR_COUNSELLING",
  FINANCIAL_COUNSELLING = "FINANCIAL_COUNSELLING",
  SPOT_COUNSELLING = "SPOT_COUNSELLING",
  OFFICE_HOUR = "OFFICE_HOUR",
  OFFICE_HOUR_COUNSELLOR = "OFFICE_HOUR_COUNSELLOR",
  OFFICE_HOUR_RM = "OFFICE_HOUR_RM",
  OFFICE_HOUR_VC = "OFFICE_HOUR_VC",
  LS_EVENTS = "LS_EVENTS",
}
export enum MeetingType {
  ONE_TO_ONE = "ONE_TO_ONE",
  ONE_TO_MANY = "ONE_TO_MANY",
  WELCOME_CALL = "WELCOME_CALL",
  OFFICE_HOUR = "OFFICE_HOUR",
  SHORTLIST_DISCUSSION = "SHORTLIST_DISCUSSION",
  SHORTLIST_REVISION = "SHORTLIST_REVISION",
  ADMIT_DISCUSSION = "ADMIT_DISCUSSION",
  COLLEGE_FINALISATION = "COLLEGE_FINALISATION",
  VISA_COUNSELLING = "VISA_COUNSELLING",
}
export enum SlotTypes {
  LA_COUNSELLING = "LA_COUNSELLING",
}
export enum LaFeesType {
  DEPOSIT = "DEPOSIT",
  APPLICATION = "APPLICATION",
}
export enum LAApplicationStatus {
  LA_APP_FEE_INITIATED = "LA_APP_FEE_INITIATED",
  INITIAL_SHORTLIST = "Initial Shortlist",
  APP_FEE_PAID = "App Fee Paid",
  DROPPED = "Dropped",
  ADMIT_DECLINED = "Admit Declined",
  CONDITIONAL_OFFER_RECEIVED = "Conditional Offer Received",
  DEPOSIT_FEE_PAID = "Deposit Fee Paid",
}

export type PaymentDescription = {
  subtitle: string;
  features: string[];
  redirectionTag: string; //html tag will be there in this key
  support_channel: string;
};
export interface ProductBundle {
  id: number;
  bundleName: string;
  title: string;
  description: PaymentDescription;
}
export interface LACourseDto {
  id: number;
  name: string;
  university: string;
  // productBundleName: string;
  pricingData: {
    basePrice: number;
    offerPrice: number;
    currency: string;
    bundleId: number;
    pricingVersion: number;
  };
  productBundle: ProductBundle;
  pricingVersion: number;
  appFeeCollectionRequired: boolean;
  depositFeeRequired: boolean;
  intake: string;
  country: string;
  isActive: boolean;
  defaultDiscountForCourse: number;
  bannerLink: string | null;
  mobileBannerLink: string | null;
}
export interface LaApplicationResponseDto {
  id: number;
  opportunity: {
    preUserId: number;
  };
  counsellor: {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    id: number;
    userId: number;
  };
  course: LACourseDto;
  status: LAApplicationStatus;
  feesType: LaFeesType;
  remark: string;
  applicationName: string;

  name: string;
  email: string;
  phone: number;
}
