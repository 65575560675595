import { getObjectKeys } from "@/utils";
import { imageBasePath } from "@/utils/imageUtils";

const IMAGEPATHS = {
  backArrowSmall: "/dashboard_assets/images/icons/back_small_arrow.svg",
  greenTickCircle: "/dashboard_assets/images/icons/green_tick_circled.svg",
  circleInCircle: "/dashboard_assets/images/icons/circle_in_circle.svg",
  whiteTickCircle: "/dashboard_assets/images/icons/white_tick_circled.svg",
  LOGIN_CARD_IMAGE: "/dashboard_assets/images/login/login_img.svg",
  SAD_EMOJI: "/dashboard_assets/images/ielts/happy 2.svg",
  NO_COUNSELLORS: "/dashboard_assets/images/calendar/no_counsellors.png",
  LIKES: "/dashboard_assets/images/icons/likes.svg",
  LEFT_ICON: "/dashboard_assets/images/icons/left.svg",
  RIGHT_ICON: "/dashboard_assets/images/icons/right.svg",
  PAPER: "/dashboard_assets/images/calendar/paper.svg",
  CONTACT: "/dashboard_assets/images/calendar/contact.svg",
  GRADUATION: "/dashboard_assets/images/calendar/graduation.svg",
  whitearrow: "/dashboard_assets/images/white-arrow.svg",
  backicon: "/dashboard_assets/images/calendar/back-icon.svg",
  greetingIcon: "/dashboard_assets/images/icons/greeting.svg",
  calendar: "/dashboard_assets/images/calendar/calendar.svg",
  clock: "/dashboard_assets/images/calendar/clock.svg",
  random: "/dashboard_assets/images/icons/random.svg",
  zoom: "/dashboard_assets/images/icons/zoom.png",
  white_arrow_small: "/dashboard_assets/images/white_arrow_small.svg",
  confetti_web: "/dashboard_assets/images/icons/confetti_web.svg",
  confetti_mweb: "/dashboard_assets/images/icons/confetti.svg",
  greenDot: "/dashboard_assets/images/icons/recommend_dot.svg",
  buildingsmweb: "/dashboard_assets/images/calendar/buildingsmweb.svg",
  buildingsweb: "/dashboard_assets/images/calendar/buildingsweb.svg",
};

export const IMAGE: typeof IMAGEPATHS = getObjectKeys(IMAGEPATHS).reduce(
  (prev, curr: keyof typeof IMAGEPATHS) => {
    prev[curr] = imageBasePath + prev[curr];
    return prev;
  },
  IMAGEPATHS,
);
