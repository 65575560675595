var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"aac8e0587ea4bea2246b25a50daa342163ec7c51"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/leap_advantage";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { getUserId } from "@/utils/cookieUtils";
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
Sentry.setUser({ username: getUserId(null) });
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.25,
    integrations: [
      new CaptureConsoleIntegration({
        levels: [`error`],
      }),
      new Sentry.Replay(),
    ],
    ignoreErrors: [
      // Specify the error patterns or types to ignore
      "analytics.js",
      /(analytics\.js|Analytics\.js)/,
      "segment",
      /segment|Segment/,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
