import { RequestMethods } from "@/utils/BulkApiTypes";
import { BaseResponse, UserInfoInterface } from "redux/services/Apitypes";
import { dashboardRouterBaseApiWithTags } from "redux/services/base/dashboardRouter";
export const userInfoApi = dashboardRouterBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUserData: builder.query<BaseResponse<UserInfoInterface>, void>({
      query: () => `student/basic`,
    }),
    postQeFormData: builder.mutation<BaseResponse<null>, any>({
      query: (payload) => ({
        url: "leap-advantage/pre-user-details",
        method: RequestMethods.POST,
        body: payload,
      }),
    }),
    getStudentEligibility: builder.query<BaseResponse<boolean>, void>({
      query: () => "/leap-advantage/slots/eligibility",
    }),
  }),
});
export const {
  useLazyGetUserDataQuery,
  useGetUserDataQuery,
  usePostQeFormDataMutation,
  useLazyGetStudentEligibilityQuery,
} = userInfoApi;
