import {
  emailRegex,
  nameRegex,
  numbersAndAlphabetsRegex,
  tenDigitMobileRegex,
} from "@/constant/regexConstants";
import { getObjectKeys } from "@/utils";
export enum FormFieldTypes {
  select_dropdown = "select_dropdown",
  single_select_tile = "single_select_tile",
  textInput = "textInput",
  numberInput = "numberInput",
}
export enum FormdId {
  LA_QE_Form_1 = "LA_QE_Form_1",
}
export enum LaQeFormFields {
  name = "name",
  emailId = "emailId",
  phone = "phone",
  preferredCountry = "preferredCountry",
  prefferedCountrySpecified = "prefferedCountrySpecified",
  preferredProgram = "preferredProgram",
  prefferedProgramSpecified = "prefferedProgramSpecified",
  preferredIntake = "preferredIntake",
  highestQualification = "highestQualification",
  highestQualificationSpecified = "highestQualificationSpecified",
  gradeNumber = "gradeNumber",
  gradeType = "gradeType",
  workExperience = "workExperience",
}
export const laQeFormRequiredFields = [
  LaQeFormFields.name,
  LaQeFormFields.emailId,
  LaQeFormFields.phone,
  LaQeFormFields.preferredCountry,
  // LaQeFormFields.prefferedCountrySpecified,
  LaQeFormFields.preferredProgram,
  // LaQeFormFields.prefferedProgramSpecified,
  LaQeFormFields.preferredIntake,
  LaQeFormFields.highestQualification,
  // LaQeFormFields.highestQualificationSpecified,
  LaQeFormFields.gradeNumber,
  LaQeFormFields.gradeType,
  LaQeFormFields.workExperience,
];
export const LaQeFormFieldsIds = {
  [LaQeFormFields.preferredCountry]: 1,
  [LaQeFormFields.prefferedCountrySpecified]: 2,
  [LaQeFormFields.preferredProgram]: 3,
  [LaQeFormFields.prefferedProgramSpecified]: 4,
  [LaQeFormFields.preferredIntake]: 5,
  [LaQeFormFields.highestQualification]: 6,
  [LaQeFormFields.highestQualificationSpecified]: 7,
  [LaQeFormFields.gradeNumber]: 8,
  [LaQeFormFields.workExperience]: 13,
  [LaQeFormFields.gradeType]: 9,
  [LaQeFormFields.name]: 10,
  [LaQeFormFields.emailId]: 11,
  [LaQeFormFields.phone]: 12,
  formId: FormdId.LA_QE_Form_1,
};
export enum WorkExperienceLabels {
  STUDENT = "I'm a student",
  _0_TO_2_YEARS = "0-2 years",
  _2_TO_7_YEARS = "2-7 years",
  _7_PLUS_YEARS = "7+ years",
}
export enum WorkExperienceValues {
  STUDENT = "STUDENT",
  _0_TO_2_YEARS = "0_TO_2_YEARS",
  _2_TO_7_YEARS = "2_TO_7_YEARS",
  _7_PLUS_YEARS = "7_PLUS_YEARS",
}
export enum PrefferedProgramOptions {
  Computer_Information_Systems = "Computer Information Systems",
  Computer_Science = "Computer Science",
  Data_Analytics = "Data Analytics",
  Data_Science = "Data Science",
  Mechanical_Engineering = "Mechanical Engineering",
  Finance = "Finance",
  Health_Informatics = "Health Informatics",
  Other = "Other",
}
export enum HighestQualificationValues {
  Engineering = "Engineering (B.Tech./B.E.)",
  ThreeyDegree = "3-year qualification degree",
  Masters_degree = "Master's degree",
  Other = "Other ",
}
export enum HighestQualificationLabels {
  Engineering = "Engineering (B.Tech./B.E.)",
  ThreeyDegree = "BCom/BCA/BBA/B.SC.",
  Masters_degree = "Master's degree",
  Other = "Other ",
}
export enum PreferredCountryOptions {
  USA = "USA 🇺🇸",
  Other = "Other",
}
export enum PreferredIntakeOptions {
  January_2025 = "January 2025",
  September_2025 = "September 2025",
  Early_2026 = "Year 2026",
}
export enum PreferredIntakeOptionsLabels {
  January_2025 = "Jan 2025",
  September_2025 = "Sept 2025",
  Early_2026 = "Early in 2026",
}
export enum CgpaOrPercentOptions {
  Percent = "PERCENTAGE",
  CGPA = "CGPA",
}
export const formDetails = {
  name: {
    label: "Enter your name*",
    type: FormFieldTypes.textInput,
    fieldValue: LaQeFormFields.name,
    question_text: "Enter your contact details to check your eligibility",
    options: [],
    default_visibility: true,
    visibility_dependency: {
      dependencies: [
        {
          question_id: 0,
          value: [],
        },
      ],
    },
    validation: {
      pattern: nameRegex,
      errorMessage: "Enter correct name",
    },
  },
  email: {
    label: "Enter your email ID*",
    type: FormFieldTypes.textInput,
    fieldValue: LaQeFormFields.emailId,
    options: [],
    default_visibility: true,
    visibility_dependency: {
      dependencies: [
        {
          question_id: 0,
          value: [],
        },
      ],
    },
    validation: {
      pattern: emailRegex,
      errorMessage: "Please enter correct email",
    },
  },
  phone: {
    label: "Enter your 10 digit phone number*",
    type: FormFieldTypes.numberInput,
    fieldValue: LaQeFormFields.phone,
    options: [],
    default_visibility: true,
    visibility_dependency: {
      dependencies: [
        {
          question_id: 0,
          value: [],
        },
      ],
    },
    validation: {
      pattern: tenDigitMobileRegex,
      errorMessage: "please enter correct number",
    },
  },
};

export const laQeForm: any = {
  form_id: FormdId.LA_QE_Form_1,
  form_meta: {
    title: "",
    image: "",
  },
  no_of_steps: 3,
  page_distribution: [5, 4, 3],
  is_auto_progressive: true,
  required: laQeFormRequiredFields,
  fields: [
    {
      question_id: LaQeFormFieldsIds.preferredCountry,
      label: "Where do you want to study Master’s? *",
      type: FormFieldTypes.single_select_tile,
      fieldValue: LaQeFormFields.preferredCountry,
      question_text: "Where do you want to study Master’s? *",
      options: getObjectKeys(PreferredCountryOptions).map((item) => ({
        label: PreferredCountryOptions[item],
        value: item,
      })),
      default_visibility: true,
      validation: { pattern: "", errorMessage: "" },
      numGridColumns: 4,
    },
    {
      question_id: LaQeFormFieldsIds.prefferedCountrySpecified,
      label: "Enter your preferred country",
      type: FormFieldTypes.textInput,
      fieldValue: LaQeFormFields.prefferedCountrySpecified,
      options: [],
      default_visibility: false,
      visibility_dependency: {
        dependencies: [
          {
            question_id: LaQeFormFieldsIds.preferredCountry,
            value: [PreferredCountryOptions.Other],
          },
        ],
      },
      validation: {
        pattern: nameRegex,
        errorMessage: "Enter correct country",
      },
    },
    {
      question_id: LaQeFormFieldsIds.preferredProgram,
      label: "Select your preferred course",
      type: FormFieldTypes.select_dropdown,
      fieldValue: LaQeFormFields.preferredProgram,
      question_text: "What is your preferred program? *",
      options: getObjectKeys(PrefferedProgramOptions).map((item) => ({
        label: PrefferedProgramOptions[item],
        value: PrefferedProgramOptions[item],
      })),
      default_visibility: true,
      validation: { pattern: "", errorMessage: "" },
    },
    {
      question_id: LaQeFormFieldsIds.prefferedProgramSpecified,
      label: "Other (Please Specify)",
      type: FormFieldTypes.textInput,
      fieldValue: LaQeFormFields.prefferedProgramSpecified,
      options: [],
      default_visibility: false,
      visibility_dependency: {
        dependencies: [
          {
            question_id: LaQeFormFieldsIds.preferredProgram,
            value: [PrefferedProgramOptions.Other],
          },
        ],
      },
      validation: {
        pattern: nameRegex,
        errorMessage: "Enter correct name",
      },
    },
    {
      question_id: LaQeFormFieldsIds.preferredIntake,
      label: "In which intake are you planning to enroll? *",
      type: FormFieldTypes.single_select_tile,
      fieldValue: LaQeFormFields.preferredIntake,
      question_text: "In which intake are you planning to enroll? *",
      options: getObjectKeys(PreferredIntakeOptions).map((item) => ({
        label: PreferredIntakeOptionsLabels[item],
        value: PreferredIntakeOptions[item],
      })),
      default_visibility: true,
      validation: { pattern: "", errorMessage: "" },
    },
    {
      question_id: LaQeFormFieldsIds.highestQualification,
      label: "Select your highest qualification",
      type: FormFieldTypes.select_dropdown,
      fieldValue: LaQeFormFields.highestQualification,
      question_text: "What is your highest level of education? *",
      options: getObjectKeys(HighestQualificationLabels).map((item) => ({
        label: HighestQualificationLabels[item],
        value: HighestQualificationValues[item],
      })),
      default_visibility: true,
      validation: { pattern: "", errorMessage: "" },
    },
    {
      question_id: LaQeFormFieldsIds.highestQualificationSpecified,
      label: "Other (Please Specify)",
      type: FormFieldTypes.textInput,
      fieldValue: LaQeFormFields.highestQualificationSpecified,
      options: [],
      default_visibility: false,
      visibility_dependency: {
        dependencies: [
          {
            question_id: LaQeFormFieldsIds.highestQualification,
            value: [HighestQualificationValues.Other],
          },
        ],
      },
      validation: {
        pattern: numbersAndAlphabetsRegex,
        errorMessage: "Enter correct name",
      },
    },
    {
      question_id: LaQeFormFieldsIds.gradeNumber,
      label: "Eg. 7.5",
      type: FormFieldTypes.numberInput,
      fieldValue: LaQeFormFields.gradeNumber,
      question_text: "What is your graduation grade? *",
      options: [],
      default_visibility: true,
      visibility_dependency: {
        dependencies: [
          {
            question_id: 0,
            value: [],
          },
        ],
      },
      validation: {
        pattern: "",
        errorMessage: "",
      },
    },
    {
      question_id: LaQeFormFieldsIds.workExperience,
      label: "How many years of work experience do you have?  *",
      type: FormFieldTypes.single_select_tile,
      fieldValue: LaQeFormFields.workExperience,
      question_text: "How many years of work experience do you have?  *",
      options: getObjectKeys(WorkExperienceLabels).map((item) => ({
        label: WorkExperienceLabels[item],
        value: WorkExperienceValues[item],
      })),
      default_visibility: true,
      numGridColumns: 4,
      validation: { pattern: "", errorMessage: "" },
    },

    {
      question_id: LaQeFormFieldsIds.name,
      ...formDetails.name,
    },
    {
      question_id: LaQeFormFieldsIds.emailId,
      ...formDetails.email,
    },
    {
      question_id: LaQeFormFieldsIds.phone,
      ...formDetails.phone,
    },
  ],
};

export const formSteps = [
  {
    levelNo: 1,
    text: "Preferences",
  },
  {
    levelNo: 2,
    text: "Personal Details",
  },
  {
    levelNo: 3,
    text: "Contact Details",
  },
];
