import { getUtmData } from "@/utils/utmUtils";
import { getCookie } from "@/utils/cookieUtils";
import { osName } from "react-device-detect";
import timeZoneCityToCountry from "tz-cities-to-countries.json";
import {
  checkIfItsServer,
  getUserDeviceName,
  mobileCheck,
} from "@/utils/browserUtils";
import { LaQeFormFields } from "@/component/LaForm/formJson";

declare global {
  interface Window {
    analytics: any;
    fbq: any;
  }
}

declare global {
  interface Document {
    document: any;
  }
}

const timestamp = "NA";

const utmDetails = getUtmData(null);
const userId = getCookie(null, "user_id");
let userCountry: string;
try {
  if (Intl) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split("/");
    const userCity = tzArr[tzArr.length - 1];
    userCountry =
      timeZoneCityToCountry[userCity as keyof typeof timeZoneCityToCountry];
  }
} catch (error) {
  console.log(error, "error");
}

export const trackSignup = async (eventName: string, props: any) => {
  try {
    if (window.analytics) {
      const eventPayload = {
        pageName: window.location.href || "NA",
        authType: props.authType || "NA",
        utmCampaign: props.utmCampaign || "NA",
        utmSource: props?.utmSource || "NA",
        utmTerm: props?.utmTerm || "NA",
        adId: props?.adId || "NA",
        flowType: props.flowType || "NA",
        subscribedToWhatsapp: !!props.subscribedToWhatsapp,
        timestamp: timestamp,
        country: props?.country || "NA",
        Platform: getPlatform(),
      };
      const userPayload = {
        pageName: window.location.href || "NA",
        utmCampaign: props.utmCampaign || "NA",
        utmSource: props?.utmSource || "NA",
        utmTerm: props?.utmTerm || "NA",
        adId: props?.adId || "NA",
        flowType: props.flowType || "NA",
        subscribedToWhatsapp: !!props?.subscribedToWhatsapp,
        userId: props?.userId || "NA",
        phone: props?.phone ? props?.phone : undefined,
        timestamp: timestamp,
        country: props?.country || "NA",
      };
      window.analytics.identify(props.userId, userPayload);
      window.analytics.track(eventName, eventPayload);
    }
  } catch (e) {
    console.log("event err", e);
  }
};

export const trackPageExit = (name: string, properties?: any) => {
  const eventName = "Page Exit";

  const props = {
    pageName: name || "NA",
    pageUrl: window.location.href || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    platform: mobileCheck() ? "Mweb" : "WEB",
    timestamp: timestamp,
    referrer: document?.referrer || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    eventId: properties?.eventId || "NA",
  };
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackPage = (name: string, properties?: any) => {
  const eventName = "Page Viewed";

  const props = {
    pageName: name || "NA",
    pageUrl: window.location.href || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    adId: utmDetails?.adId || "NA",
    utmMedium: utmDetails?.utmMedium || "NA",
    platform: mobileCheck() ? "Mweb" : "WEB",
    timestamp: timestamp,
    referrer: document?.referrer || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    eventId: properties?.eventId || "NA",
    screenType: properties?.screenType || "NA",
    programName: properties?.programName || "NA",
    ...properties,
  };
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackPageViewWithContent = (name: string, props: any) => {
  const eventName = "Page Viewed";
  const data = {
    pageName: name || "NA",
    pageUrl: window.location.href || "NA",
    contentName: props?.contentName || "NA",
    contentFormat: props.contentFormat || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    platform: mobileCheck() ? "Mweb" : "WEB",
    timestamp: timestamp,
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    modalName: props?.modalName,
    applicationStage: props?.applicationStage,
    screenType: props.screenType || "NA",
  };
  const userPayload = {
    pageName: window.location.href || "NA",
    utmCampaign: props.utmCampaign || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmSource: props?.utmSource || "NA",
    utmTerm: props?.utmTerm || "NA",
    adId: props?.adId || "NA",
    flowType: props.flowType || "NA",
    subscribedToWhatsapp: true,
    userId: props?.userId || "NA",
    phone: props?.phone ? props?.phone : undefined,
    timestamp: timestamp,
    country: props?.country || "NA",
  };
  if (window.analytics) {
    window.analytics.track(eventName, data);
    window.analytics.identify(props?.userId, userPayload);
  }
};

export const trackIdentityAsync = async (eventName: string, props: any) => {
  if (window.analytics) {
    return new Promise((resolve) => {
      window.analytics.identify(eventName, props, {}, () => {
        resolve(true);
      });
    });
  }
};

export const trackIeltsEvent = (eventName: string, props: any) => {
  props = {
    ...props,
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    referrer: document?.referrer || "NA",
  };
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackClick = (name: any, props: any) => {
  const eventName = "Click";
  //  // add a new field in the array below which is passed in a set
  const eventFields = [
    "pageName",
    "pageNumber",
    "pageContent",
    "widgetFormat",
    "widgetName",
    "contentName",
    "contentFormat",
    "utmCampaign",
    "utmSource",
    "utmTerm",
    "adId",
    "timestamp",
    "eventId",
    "eventID2",
    "QuizScore",
    "eventCategory",
    "teacher",
    "startDate",
    "endDate",
    "title",
    "startTime",
    "endTime",
    "eventLink",
    "referrer",
    "sectionName",
    "filterName",
    "offerPrice",
    "Rewardstatus",
    "RewardDay",
    "RewardName",
    "Status",
    "contentPage",
    "product",
    "buttonName",
    "type",
    "EducatorName",
    "platform",
    "pageUrl",
    "bundleTitle",
    "bundleType",
    "counsellingStage",
    "course",
    "pageType",
    "errorMessage",
    "phoneNumber",
    "errorCode",
    "slotType",
    "programName",
    "screenType",
    "couponCode",
  ];
  let eventData: any = eventFields.reduce((accumulator: any, currentValue) => {
    if (props[currentValue] || typeof props[currentValue] === "number") {
      accumulator[currentValue] = props[currentValue];
    }
    return accumulator;
  }, {});

  const defaultFields = {
    pageName: name || "NA",
    referrer: document?.referrer || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    timestamp: timestamp || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    pageUrl: window.location.href,
    Platform: getPlatform(),
    country: userCountry || "N/A",
  };
  eventData = { ...eventData, ...defaultFields };
  if (window.analytics) {
    window.analytics.track(eventName, eventData);
  }
};

export const trackIeltsEventAttend = (props: any) => {
  const eventName = "Event Attended";
  const data = {
    eventId: `${props?.eventId || "NA"}`,
    eventCategory: props?.eventCategory || "NA",
    teacher: props?.teacher || "NA",
    meetingDate: props?.meetingDate || "NA",
    title: props?.title || "NA",
    meetingTime: props?.meetingTime || "NA",
    eventLink: props?.eventLink || "NA",
    batchDay: props?.batchDay || "NA",
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackIeltsFormAsync = (
  eventName: string,
  props: any,
): Promise<any> => {
  return new Promise(async (resolve) => {
    let email = undefined;
    if (props.email) email = props.email;
    else if (props.emailId) email = props.emailId;
    const eventPayload = {
      [LaQeFormFields.name]: props?.name || "NA",
      [LaQeFormFields.emailId]: email || props?.emailId || "NA",
      [LaQeFormFields.phone]: props?.phone || "NA",
      [LaQeFormFields.preferredCountry]: props?.preferredCountry || "NA",
      [LaQeFormFields.prefferedCountrySpecified]:
        props?.prefferedCountrySpecified || "NA",
      [LaQeFormFields.preferredProgram]: props?.preferredProgram || "NA",
      [LaQeFormFields.prefferedProgramSpecified]:
        props?.prefferedProgramSpecified || "NA",
      [LaQeFormFields.preferredIntake]: props?.preferredIntake || "NA",
      [LaQeFormFields.highestQualification]:
        props?.highestQualification || "NA",
      [LaQeFormFields.highestQualificationSpecified]:
        props?.highestQualificationSpecified || "NA",
      [LaQeFormFields.gradeNumber]: props?.gradeNumber || "NA",
      [LaQeFormFields.gradeType]: props?.gradeType || "NA",
      [LaQeFormFields.workExperience]: props?.workExperience || "NA",
      formType: props?.formType || "NA",
      eventId: `${props?.eventId || "NA"}`,
      pageName: props?.pageName || "NA",
      widgetFormat: props?.widgetFormat || "NA",
      widgetName: props?.widgetName || "NA",
      contentName: props?.contentName || "NA",
      contentFormat: props?.contentFormat || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      adId: utmDetails?.adId || "NA",
      rating: `${props.rating || "NA"}`,
      timestamp: timestamp,
      referrer: document?.referrer || "NA",
      formId: props.formId || "NA",
    };

    const userPayload = {
      [LaQeFormFields.name]: props?.name || "NA",
      [LaQeFormFields.emailId]: props?.emailId || "NA",
      [LaQeFormFields.phone]: props?.phone || "NA",
      [LaQeFormFields.preferredCountry]: props?.preferredCountry || "NA",
      [LaQeFormFields.prefferedCountrySpecified]:
        props?.prefferedCountrySpecified || "NA",
      [LaQeFormFields.preferredProgram]: props?.preferredProgram || "NA",
      [LaQeFormFields.prefferedProgramSpecified]:
        props?.prefferedProgramSpecified || "NA",
      [LaQeFormFields.preferredIntake]: props?.preferredIntake || "NA",
      [LaQeFormFields.highestQualification]:
        props?.highestQualification || "NA",
      [LaQeFormFields.highestQualificationSpecified]:
        props?.highestQualificationSpecified || "NA",
      [LaQeFormFields.gradeNumber]: props?.gradeNumber || "NA",
      [LaQeFormFields.gradeType]: props?.gradeType || "NA",
    };
    if (window.analytics) {
      await Promise.all([
        trackIdentityAsync(userId, userPayload),
        trackEventAsync(eventName, eventPayload),
      ]);
      resolve(true);
    }
  });
};

export const trackIeltsError = (props: any) => {
  const eventName = "Error";
  const data = {
    eventId: `${props?.eventId || "NA"}`,
    eventCategory: props?.eventCategory || "NA",
    meetingDate: props?.meetingDate || "NA",
    title: props?.title || "NA",
    teacher: props?.teacher || "NA",
    meetingTime: props?.meetingTime || "NA",
    eventLink: props?.eventLink || "NA",
    errorCategory: props?.errorCategory || "NA",
    errorMessage: props?.errorMessage || "NA",
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};
export const trackEventAsync = async (name: any, props?: any) => {
  const eventName = name || "";
  if (window.analytics) {
    return new Promise((resolve) => {
      window.analytics.track(
        eventName,
        {
          ...props,
          utmCampaign: utmDetails?.utmCampaign || "NA",
          utmSource: utmDetails?.utmSource || "NA",
          utmTerm: utmDetails?.utmTerm || "NA",
          gclid: utmDetails?.gclid || "NA",
          adName: utmDetails?.adName || "NA",
          utmMedium: utmDetails?.utmMedium || "NA",
          adId: utmDetails?.adId || "NA",
        },
        {},
        () => {
          resolve(true);
        },
      );
    });
  }
};

export const trackEvent = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackProductFormAsync = (eventName: string, props: any) => {
  return new Promise((resolve) => {
    if (window.analytics) {
      const eventPayload = {
        ...props,
        timestamp: timestamp,
      };
      const userPayload = {
        eventCategory: props?.eventCategory || "NA",
        productCategory: props?.productCategory || "NA",
        cohorts: props?.cohorts || "NA",
      };
      window.analytics.identify(userId, userPayload);
      window.analytics.track(eventName, eventPayload);
      resolve(true);
    }
  });
};

export const trackForm = async (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      formType: props?.formType || "NA",
      formId: props?.formId || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      adId: utmDetails?.adId || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      timestamp: timestamp,
      phone: props?.phone || "NA",
      name: props?.name || "NA",
      Platform: getPlatform(),
      howLeapHelp: props?.howLeapHelp || "NA",
      pageUrl: window?.location?.href || "NA",
    };

    const userPayload = {
      admitStatus:
        props?.admitDetails?.admitStatus || props?.admitStatus || "NA",
      college: props?.college || "NA",
      consultant: props?.consultant || "NA",
      helpForAdmit: props?.helpForAdmit || "NA",
      email: props?.email ? props?.email : undefined,
      gradeMetric: props?.gradeMetric || "NA",
      grades: props?.grades || "NA",
      highestLevelEducation: props?.highestLevelEducation || "NA",
      ieltsStatus: props?.ieltsStatus || "NA",
      journeyStatus: props?.journeyStatus || "NA",
      payOfExpenses: props?.payOfExpenses || "NA",
      preferredCountry: props?.preferredCountry || "NA",
      preferredIntake: props?.preferredIntake || "NA",
      preferredProgram: props?.preferredProgram || "NA",
      timestamp: timestamp,
      ieltsType: props?.ieltsType || "NA",
      ieltsPurpose: props?.ieltsPurpose || "NA",
      ieltsMonth: props?.ieltsMonth || "NA",
      ieltsYear: props?.ieltsYear || "NA",
      phone: props?.phone ? props?.phone : undefined,
      name: props?.name ? props?.name : undefined,
      howLeapHelp: props?.howLeapHelp || "NA",
      pageUrl: window?.location?.href || "NA",
    };
    window.analytics.identify(userId, userPayload);
    window.analytics.track(eventName, eventPayload);
  }
};
export const platforms = {
  android: "ANDROID",
  web: "WEB",
  mweb: "MWEB",
};
export const getPlatform = () => {
  const params = new URL(document?.location.href).searchParams;
  const platform = params.get("platform");
  if (platform == "app") return platforms.android;
  else if (mobileCheck()) return platforms.mweb;
  else return platforms.web;
};

export const trackIeltsForm = async (eventName: string, props: any) => {
  let email = undefined;
  if (props.email) email = props.email;
  else if (props.emailId) email = props.emailId;

  if (window.analytics) {
    const eventPayload = {
      formType: props?.formType || "NA",
      eventId: `${props?.eventId || "NA"}`,
      pageName: props?.pageName || "NA",
      widgetFormat: props?.widgetFormat || "NA",
      widgetName: props?.widgetName || "NA",
      contentName: props?.contentName || "NA",
      contentFormat: props?.contentFormat || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      adId: utmDetails?.adId || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      rating: `${props.rating || "NA"}`,
      timestamp: timestamp,
      referrer: document?.referrer || "NA",
      Platform: getPlatform(),
    };

    const userPayload = {
      college: props?.college || "NA",
      highestLevelEducation: props?.highestLevelEducation || "NA",
      ieltsStatus: props?.ieltsStatus || "NA",
      email,
      journeyStatus: props?.journeyStatus || "NA",
      payOfExpenses: props?.payOfExpenses || "NA",
      preferredCountry: props?.preferredCountry || "NA",
      preferredIntake: props?.preferredIntake || "NA",
      preferredProgram: props?.preferredProgram || "NA",
      timestamp: timestamp,
      currentEnglishLevel: props?.currentEnglishLevel || "NA",
      ieltsExamMonth: props?.ieltsExamMonth || "NA",
      prefferedTimeSlot: props?.prefferedTimeSlot || "NA",
    };
    window.analytics.identify(userId, userPayload);
    window.analytics.track(eventName, eventPayload);
  }
};

export const trackUserDevice = async (): Promise<undefined> => {
  try {
    if (window.analytics) {
      await getUserDeviceName().then(
        (deviceName) =>
          deviceName &&
          new Promise((resolve) => {
            window.analytics.identify(
              userId,
              { deviceModel: deviceName },
              {},
              () => {
                resolve(true);
              },
            );
          }),
      );
    }
  } catch (e) {
    console.log("err", e);
  }
  return;
};

export const getPlatformV2 = () => {
  if (typeof window != "undefined") {
    const params = new URL((document as any).location).searchParams;
    const platform = params.get("platform");
    if (platform == "app") return platforms.android;
    if (mobileCheck()) return platforms.mweb;
    return platforms.web;
  }
  return "";
};

export const trackPixelEvent = (eventName: string, eventProps: any) => {
  if (!checkIfItsServer()) {
    window.fbq(eventName, eventProps);
  }
};
